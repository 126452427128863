/* Index.svelte generated by Svelte v3.22.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_block,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	update_keyed_each
} from "svelte/internal";

import {
	Payload,
	NatsConnection,
	ConnectionOptions,
	Msg,
	NatsError
} from "nats.ws";

import * as nats from "nats.ws";
import { NegAnnouncer } from "./natsneg.ts";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[12] = list[i];
	child_ctx[14] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	child_ctx[14] = i;
	return child_ctx;
}

// (83:0) {#if connected}
function create_if_block(ctx) {
	let table0;
	let each_blocks_1 = [];
	let each0_lookup = new Map();
	let t;
	let table1;
	let each_blocks = [];
	let each1_lookup = new Map();
	let each_value_1 = /*discoveries*/ ctx[4];
	const get_key = ctx => /*discoveries*/ ctx[4].id;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each0_lookup.set(key, each_blocks_1[i] = create_each_block_1(key, child_ctx));
	}

	let each_value = /*subscriptions*/ ctx[5];
	const get_key_1 = ctx => /*sub*/ ctx[12].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key_1(child_ctx);
		each1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			table0 = element("table");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t = space();
			table1 = element("table");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
		},
		m(target, anchor) {
			insert(target, table0, anchor);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(table0, null);
			}

			insert(target, t, anchor);
			insert(target, table1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(table1, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*discoveries*/ 16) {
				const each_value_1 = /*discoveries*/ ctx[4];
				each_blocks_1 = update_keyed_each(each_blocks_1, dirty, get_key, 1, ctx, each_value_1, each0_lookup, table0, destroy_block, create_each_block_1, null, get_each_context_1);
			}

			if (dirty & /*subscriptions*/ 32) {
				const each_value = /*subscriptions*/ ctx[5];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key_1, 1, ctx, each_value, each1_lookup, table1, destroy_block, create_each_block, null, get_each_context);
			}
		},
		d(detaching) {
			if (detaching) detach(table0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].d();
			}

			if (detaching) detach(t);
			if (detaching) detach(table1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

// (85:4) {#each discoveries as discoveries, i (discoveries.id)}
function create_each_block_1(key_1, ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[14] + 1 + "";
	let t0;
	let td1;
	let t1_value = /*discoveries*/ ctx[4].id + "";
	let t1;

	return {
		key: key_1,
		first: null,
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			td1 = element("td");
			t1 = text(t1_value);
			this.first = tr;
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, td1);
			append(td1, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*discoveries*/ 16 && t0_value !== (t0_value = /*i*/ ctx[14] + 1 + "")) set_data(t0, t0_value);
			if (dirty & /*discoveries*/ 16 && t1_value !== (t1_value = /*discoveries*/ ctx[4].id + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

// (91:4) {#each subscriptions as sub, i (sub.id)}
function create_each_block(key_1, ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[14] + 1 + "";
	let t0;
	let td1;
	let t1_value = /*sub*/ ctx[12].id + "";
	let t1;
	let td2;
	let t2_value = /*sub*/ ctx[12].subj + "";
	let t2;

	return {
		key: key_1,
		first: null,
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			td1 = element("td");
			t1 = text(t1_value);
			td2 = element("td");
			t2 = text(t2_value);
			this.first = tr;
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, td1);
			append(td1, t1);
			append(tr, td2);
			append(td2, t2);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function create_fragment(ctx) {
	let p;
	let t0;
	let input0;
	let t1;
	let input1;
	let t2;
	let button;
	let t3;
	let button_disabled_value;
	let t4;
	let if_block_anchor;
	let dispose;
	let if_block = /*connected*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			p = element("p");
			t0 = text("User: ");
			input0 = element("input");
			t1 = text("\nPass3: ");
			input1 = element("input");
			t2 = space();
			button = element("button");
			t3 = text("Connect");
			t4 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(input0, "type", "text");
			input0.disabled = /*connected*/ ctx[0];
			attr(input1, "type", "text");
			input1.disabled = /*connected*/ ctx[0];
			button.disabled = button_disabled_value = /*connecting*/ ctx[1] || /*connected*/ ctx[0];
		},
		m(target, anchor, remount) {
			insert(target, p, anchor);
			append(p, t0);
			append(p, input0);
			set_input_value(input0, /*uname*/ ctx[2]);
			append(p, t1);
			append(p, input1);
			set_input_value(input1, /*password*/ ctx[3]);
			append(p, t2);
			append(p, button);
			append(button, t3);
			insert(target, t4, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			if (remount) run_all(dispose);

			dispose = [
				listen(input0, "input", /*input0_input_handler*/ ctx[10]),
				listen(input1, "input", /*input1_input_handler*/ ctx[11]),
				listen(button, "click", /*cookieAuth*/ ctx[6])
			];
		},
		p(ctx, [dirty]) {
			if (dirty & /*connected*/ 1) {
				input0.disabled = /*connected*/ ctx[0];
			}

			if (dirty & /*uname*/ 4 && input0.value !== /*uname*/ ctx[2]) {
				set_input_value(input0, /*uname*/ ctx[2]);
			}

			if (dirty & /*connected*/ 1) {
				input1.disabled = /*connected*/ ctx[0];
			}

			if (dirty & /*password*/ 8 && input1.value !== /*password*/ ctx[3]) {
				set_input_value(input1, /*password*/ ctx[3]);
			}

			if (dirty & /*connecting, connected*/ 3 && button_disabled_value !== (button_disabled_value = /*connecting*/ ctx[1] || /*connected*/ ctx[0])) {
				button.disabled = button_disabled_value;
			}

			if (/*connected*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(p);
			if (detaching) detach(t4);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let connected = false;
	let connecting = false;
	let subscriptions = [];
	let natsConn = null;
	let uname = "";
	let password = "";
	let announcer;
	let discoveries = [];

	function cookieAuth() {
		$$invalidate(1, connecting = true);
		const authData = new URLSearchParams();
		authData.append("user", uname);
		authData.append("pwd", password);

		let errorHandler = err => {
			window.alert(err);
			$$invalidate(1, connecting = false);
		};

		fetch("/auth", { method: "POST", body: authData }).then(result => {
			if (Math.trunc(result.status / 100) == 2) {
				result.json().then(ret => {
					natsConnect(ret);
					console.log(ret);
				}).catch(errorHandler);
			} else {
				result.text().then(err => {
					errorHandler(err);
				});
			}
		}).catch(errorHandler);
	}

	function natsConnect(jwt) {
		$$invalidate(1, connecting = true);
		let opts = { url: "wss://localhost:8443/nats" };

		NatsConnection.connect(opts).then(nc => {
			natsConn = nc;
			console.log(nc);
			$$invalidate(0, connected = true);

			natsConn.subscribe("neg.announce.>", (error, msg) => {
				let msgData = JSON.parse(msg.data);

				if (!msgData) {
					return;
				}

				if (msgData["new"]) {
					
				} //announcer.send({})

				let subj = msg.subject;
				let found = false;

				for (let i = 0; i < discoveries.length; ++i) {
					if (discoveries[i].id == subj) {
						//discoveries[i] = {id: subj}
						found = true;

						break;
					}
				}

				if (!found) {
					$$invalidate(4, discoveries = [...discoveries, { id: subj }]);
				}
			});

			announcer = new NegAnnouncer(natsConn, jwt);
		}).catch(() => {
			$$invalidate(1, connecting = false);
		});
	}

	function input0_input_handler() {
		uname = this.value;
		$$invalidate(2, uname);
	}

	function input1_input_handler() {
		password = this.value;
		$$invalidate(3, password);
	}

	return [
		connected,
		connecting,
		uname,
		password,
		discoveries,
		subscriptions,
		cookieAuth,
		natsConn,
		announcer,
		natsConnect,
		input0_input_handler,
		input1_input_handler
	];
}

class Index extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Index;