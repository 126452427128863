/* App.svelte generated by Svelte v3.22.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Router from "svelte-navaid/Router.svelte";
import Route from "svelte-navaid/Route.svelte";
import Link from "svelte-navaid/Link.svelte";
import navaidHash from "svelte-navaid/navaid-hash";
import Index from "./Index.svelte";
import NatsRTCClient from "./NatsRTCClient.svelte";

function create_default_slot_6(ctx) {
	let t;

	return {
		c() {
			t = text("Home");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (11:31) <Link href="/v">
function create_default_slot_5(ctx) {
	let t;

	return {
		c() {
			t = text("Viewer");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (11:63) <Link href="/s">
function create_default_slot_4(ctx) {
	let t;

	return {
		c() {
			t = text("Streamer");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (16:2) <Route path="/v">
function create_default_slot_2(ctx) {
	let current;
	const natsrtcclient = new NatsRTCClient({ props: { role: "v" } });

	return {
		c() {
			create_component(natsrtcclient.$$.fragment);
		},
		m(target, anchor) {
			mount_component(natsrtcclient, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(natsrtcclient.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(natsrtcclient.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(natsrtcclient, detaching);
		}
	};
}

// (20:2) <Route path="/s">
function create_default_slot_1(ctx) {
	let current;
	const natsrtcclient = new NatsRTCClient({ props: { role: "s" } });

	return {
		c() {
			create_component(natsrtcclient.$$.fragment);
		},
		m(target, anchor) {
			mount_component(natsrtcclient, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(natsrtcclient.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(natsrtcclient.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(natsrtcclient, detaching);
		}
	};
}

// (10:0) <Router library={navaidHash}>
function create_default_slot(ctx) {
	let t0;
	let t1;
	let t2;
	let t3;
	let t4;
	let current;

	const link0 = new Link({
			props: {
				href: "/",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	const link1 = new Link({
			props: {
				href: "/v",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	const link2 = new Link({
			props: {
				href: "/s",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	const route0 = new Route({ props: { path: "/" } });

	const route1 = new Route({
			props: {
				path: "/v",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	const route2 = new Route({
			props: {
				path: "/s",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link0.$$.fragment);
			t0 = text(" | ");
			create_component(link1.$$.fragment);
			t1 = text(" | ");
			create_component(link2.$$.fragment);
			t2 = space();
			create_component(route0.$$.fragment);
			t3 = space();
			create_component(route1.$$.fragment);
			t4 = space();
			create_component(route2.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link0, target, anchor);
			insert(target, t0, anchor);
			mount_component(link1, target, anchor);
			insert(target, t1, anchor);
			mount_component(link2, target, anchor);
			insert(target, t2, anchor);
			mount_component(route0, target, anchor);
			insert(target, t3, anchor);
			mount_component(route1, target, anchor);
			insert(target, t4, anchor);
			mount_component(route2, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const link0_changes = {};

			if (dirty & /*$$scope*/ 1) {
				link0_changes.$$scope = { dirty, ctx };
			}

			link0.$set(link0_changes);
			const link1_changes = {};

			if (dirty & /*$$scope*/ 1) {
				link1_changes.$$scope = { dirty, ctx };
			}

			link1.$set(link1_changes);
			const link2_changes = {};

			if (dirty & /*$$scope*/ 1) {
				link2_changes.$$scope = { dirty, ctx };
			}

			link2.$set(link2_changes);
			const route0_changes = {};

			if (dirty & /*$$scope*/ 1) {
				route0_changes.$$scope = { dirty, ctx };
			}

			route0.$set(route0_changes);
			const route1_changes = {};

			if (dirty & /*$$scope*/ 1) {
				route1_changes.$$scope = { dirty, ctx };
			}

			route1.$set(route1_changes);
			const route2_changes = {};

			if (dirty & /*$$scope*/ 1) {
				route2_changes.$$scope = { dirty, ctx };
			}

			route2.$set(route2_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link0.$$.fragment, local);
			transition_in(link1.$$.fragment, local);
			transition_in(link2.$$.fragment, local);
			transition_in(route0.$$.fragment, local);
			transition_in(route1.$$.fragment, local);
			transition_in(route2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link0.$$.fragment, local);
			transition_out(link1.$$.fragment, local);
			transition_out(link2.$$.fragment, local);
			transition_out(route0.$$.fragment, local);
			transition_out(route1.$$.fragment, local);
			transition_out(route2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link0, detaching);
			if (detaching) detach(t0);
			destroy_component(link1, detaching);
			if (detaching) detach(t1);
			destroy_component(link2, detaching);
			if (detaching) detach(t2);
			destroy_component(route0, detaching);
			if (detaching) detach(t3);
			destroy_component(route1, detaching);
			if (detaching) detach(t4);
			destroy_component(route2, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const router = new Router({
			props: {
				library: navaidHash,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};

			if (dirty & /*$$scope*/ 1) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default App;