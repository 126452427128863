/* NatsRTCClient.svelte generated by Svelte v3.22.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_block,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	update_keyed_each
} from "svelte/internal";

import {
	Payload,
	NatsConnection,
	ConnectionOptions,
	Msg,
	NatsError
} from "nats.ws";

import { NatsNegConnection, NatsState } from "./natsneg.ts";
import { IceAgent, RandomUfrag, RTCFactory } from "./nttrtc/webrtc.ts";
import { DiscoverLocalInfo, LocalInfo } from "./nttrtc/iface_discovery.ts";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[17] = list[i];
	child_ctx[19] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[20] = list[i];
	child_ctx[19] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	child_ctx[19] = i;
	return child_ctx;
}

// (156:0) {#if natsState == NatsState.Connected}
function create_if_block_1(ctx) {
	let table;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t;
	let if_block_anchor;
	let each_value_2 = /*discoveries*/ ctx[3];
	const get_key = ctx => /*discovery*/ ctx[22].id;

	for (let i = 0; i < each_value_2.length; i += 1) {
		let child_ctx = get_each_context_2(ctx, each_value_2, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block_2(key, child_ctx));
	}

	let if_block = /*peerPub*/ ctx[1] != "" && create_if_block_2(ctx);

	return {
		c() {
			table = element("table");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			insert(target, table, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(table, null);
			}

			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*peerPub, startNeg, discoveries, role, peer*/ 395) {
				const each_value_2 = /*discoveries*/ ctx[3];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value_2, each_1_lookup, table, destroy_block, create_each_block_2, null, get_each_context_2);
			}

			if (/*peerPub*/ ctx[1] != "") {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(table);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (162:8) {#if role == "v" && discovery.role == peer()}
function create_if_block_3(ctx) {
	let button;
	let t;
	let button_disabled_value;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[16](/*discovery*/ ctx[22], ...args);
	}

	return {
		c() {
			button = element("button");
			t = text("Connect");
			button.disabled = button_disabled_value = /*peerPub*/ ctx[1] != "";
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			append(button, t);
			if (remount) dispose();
			dispose = listen(button, "click", click_handler);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*peerPub*/ 2 && button_disabled_value !== (button_disabled_value = /*peerPub*/ ctx[1] != "")) {
				button.disabled = button_disabled_value;
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			dispose();
		}
	};
}

// (158:4) {#each discoveries as discovery, i (discovery.id)}
function create_each_block_2(key_1, ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[19] + 1 + "";
	let t0;
	let td1;
	let t1_value = /*discovery*/ ctx[22].role + "";
	let t1;
	let td2;
	let t2_value = /*discovery*/ ctx[22].pubKey + "";
	let t2;
	let t3;
	let td3;
	let show_if = /*role*/ ctx[0] == "v" && /*discovery*/ ctx[22].role == /*peer*/ ctx[7]();
	let t4;
	let if_block = show_if && create_if_block_3(ctx);

	return {
		key: key_1,
		first: null,
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			td1 = element("td");
			t1 = text(t1_value);
			td2 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td3 = element("td");
			if (if_block) if_block.c();
			t4 = space();
			this.first = tr;
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, td1);
			append(td1, t1);
			append(tr, td2);
			append(td2, t2);
			append(tr, t3);
			append(tr, td3);
			if (if_block) if_block.m(td3, null);
			append(tr, t4);
		},
		p(ctx, dirty) {
			if (dirty & /*discoveries*/ 8 && t0_value !== (t0_value = /*i*/ ctx[19] + 1 + "")) set_data(t0, t0_value);
			if (dirty & /*discoveries*/ 8 && t1_value !== (t1_value = /*discovery*/ ctx[22].role + "")) set_data(t1, t1_value);
			if (dirty & /*discoveries*/ 8 && t2_value !== (t2_value = /*discovery*/ ctx[22].pubKey + "")) set_data(t2, t2_value);
			if (dirty & /*role, discoveries*/ 9) show_if = /*role*/ ctx[0] == "v" && /*discovery*/ ctx[22].role == /*peer*/ ctx[7]();

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(td3, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(tr);
			if (if_block) if_block.d();
		}
	};
}

// (168:0) {#if peerPub != ""}
function create_if_block_2(ctx) {
	let t0;
	let t1;

	return {
		c() {
			t0 = text("Connecting to ");
			t1 = text(/*peerPub*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*peerPub*/ 2) set_data(t1, /*peerPub*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (174:0) {#if localInfo}
function create_if_block(ctx) {
	let table0;
	let t;
	let table1;
	let each_value_1 = /*localInfo*/ ctx[4].cands;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*localInfo*/ ctx[4].ifs;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			table0 = element("table");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t = space();
			table1 = element("table");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
		},
		m(target, anchor) {
			insert(target, table0, anchor);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(table0, null);
			}

			insert(target, t, anchor);
			insert(target, table1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(table1, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*localInfo, JSON*/ 16) {
				each_value_1 = /*localInfo*/ ctx[4].cands;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(table0, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*JSON, localInfo*/ 16) {
				each_value = /*localInfo*/ ctx[4].ifs;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(table1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(table0);
			destroy_each(each_blocks_1, detaching);
			if (detaching) detach(t);
			if (detaching) detach(table1);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (177:0) {#each localInfo.cands as cand, i}
function create_each_block_1(ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[19] + 1 + "";
	let t0;
	let td1;
	let t1_value = JSON.stringify(/*cand*/ ctx[20].toJSON()) + "";
	let t1;
	let td2;
	let t2_value = /*cand*/ ctx[20].address + "";
	let t2;
	let t3;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			td1 = element("td");
			t1 = text(t1_value);
			td2 = element("td");
			t2 = text(t2_value);
			t3 = space();
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, td1);
			append(td1, t1);
			append(tr, td2);
			append(td2, t2);
			append(tr, t3);
		},
		p(ctx, dirty) {
			if (dirty & /*localInfo*/ 16 && t1_value !== (t1_value = JSON.stringify(/*cand*/ ctx[20].toJSON()) + "")) set_data(t1, t1_value);
			if (dirty & /*localInfo*/ 16 && t2_value !== (t2_value = /*cand*/ ctx[20].address + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

// (184:0) {#each localInfo.ifs as iface, i}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[19] + 1 + "";
	let t0;
	let td1;
	let t1_value = JSON.stringify(/*iface*/ ctx[17]) + "";
	let t1;
	let t2;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			td1 = element("td");
			t1 = text(t1_value);
			t2 = space();
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, td1);
			append(td1, t1);
			append(tr, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*localInfo*/ 16 && t1_value !== (t1_value = JSON.stringify(/*iface*/ ctx[17]) + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let t1;
	let t2;
	let br;
	let t3;
	let t4;
	let t5;
	let audio;
	let t6;
	let video;
	let if_block0 = /*natsState*/ ctx[2] == NatsState.Connected && create_if_block_1(ctx);
	let if_block1 = /*localInfo*/ ctx[4] && create_if_block(ctx);

	return {
		c() {
			t0 = text(/*audioStatus*/ ctx[5]);
			t1 = text("\nNeg Status: ");
			t2 = text(/*negStatus*/ ctx[6]);
			br = element("br");
			t3 = space();
			if (if_block0) if_block0.c();
			t4 = space();
			if (if_block1) if_block1.c();
			t5 = space();
			audio = element("audio");
			t6 = space();
			video = element("video");
			attr(audio, "id", "audioTrack");
			audio.controls = true;
			audio.autoplay = true;
			attr(video, "id", "videoTrack");
			video.controls = true;
			video.autoplay = true;
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
			insert(target, br, anchor);
			insert(target, t3, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t4, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t5, anchor);
			insert(target, audio, anchor);
			insert(target, t6, anchor);
			insert(target, video, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*audioStatus*/ 32) set_data(t0, /*audioStatus*/ ctx[5]);
			if (dirty & /*negStatus*/ 64) set_data(t2, /*negStatus*/ ctx[6]);

			if (/*natsState*/ ctx[2] == NatsState.Connected) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(t4.parentNode, t4);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*localInfo*/ ctx[4]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(t5.parentNode, t5);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
			if (detaching) detach(br);
			if (detaching) detach(t3);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t4);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t5);
			if (detaching) detach(audio);
			if (detaching) detach(t6);
			if (detaching) detach(video);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { role = "" } = $$props;
	let peerPub = "";

	function peer() {
		if (role == "v") return "s";
		return "v";
	}

	let natsState = NatsState.NotConnected;
	let neg;
	let discoveries = [];

	let errorHandler = err => {
		console.log(err);
		$$invalidate(2, natsState = NatsState.NotConnected);
	};

	let localInfo;

	let localInfoGathered = DiscoverLocalInfo([{ urls: "stun:stun.l.google.com:19302" }]).then(info => {
		$$invalidate(4, localInfo = info);
	});

	let rtcFactory = new RTCFactory();
	rtcFactory.provideDataStream();
	let audioStatus = "please accept the mic request...";

	if (role == "s") {
		let streamPromise = navigator.mediaDevices.getUserMedia({ audio: true, video: true });

		streamPromise.then(stream => {
			$$invalidate(5, audioStatus = "connected");
			rtcFactory.provideTrack(stream.getVideoTracks()[0]);
			rtcFactory.provideTrack(stream.getAudioTracks()[0]);
		}).catch(reason => {
			$$invalidate(5, audioStatus = reason);
		});
	} else {
		audioStatus = "not needed";
		rtcFactory.provideTranceiver("video", { "direction": "recvonly" });
		rtcFactory.provideTranceiver("video", { "direction": "recvonly" });
		rtcFactory.provideTranceiver("audio", { "direction": "recvonly" });
	}

	//let streamObj = null
	let onTrackHandler = ev => {
		console.log(ev);

		if (ev.track.kind == "audio") {
			let audio = document.getElementById("audioTrack");
			audio.autoplay = true;
			let mediaStream = new MediaStream([ev.track]);
			audio.srcObject = mediaStream;
		} else {
			let velem = document.getElementById("videoTrack");
			velem.autoplay = true;
			let mediaStream = new MediaStream([ev.track]);
			velem.srcObject = mediaStream;
		}
	};

	function natsConnect(jwt) {
		$$invalidate(2, natsState = NatsState.Connecting);

		let opts = {
			url: "wss://" + window.location.host + "/nats"
		};

		neg = new NatsNegConnection(opts, { unsignedJwt: jwt });

		neg.onConnectionStateChanged = ns => {
			$$invalidate(2, natsState = ns);

			if (ns == NatsState.Connected && role == "s") {
				localInfoGathered.then(() => {
					let onViewerReady = streamerSession => {
						console.log("vready");
						console.log(streamerSession);
						streamerSession.connect();
					};

					neg.listenForViewers({
						factory: rtcFactory,
						localInfo,
						statusCb: status => {
							console.log("statusCb", status);
							$$invalidate(6, negStatus = status);
						},
						onViewerReady,
						onTrack: onTrackHandler
					});
				});
			}
		};

		neg.onDiscoveredPeer = msg => {
			let subj = msg.subject;
			let found = false;

			// neg.announce.role.pub
			let parts = subj.split(".");

			let obj = {
				id: subj,
				role: parts[2],
				pubKey: parts[3]
			};

			for (let i = 0; i < discoveries.length; ++i) {
				if (discoveries[i].id == subj) {
					$$invalidate(3, discoveries[i] = obj, discoveries);
					found = true;
					break;
				}
			}

			if (!found) {
				$$invalidate(3, discoveries = [...discoveries, obj]);
			}
		};

		neg.onDisconnectedPeer = msg => {
			let subj = msg.subject;

			for (let i = 0; i < discoveries.length; ++i) {
				if (discoveries[i].id == subj) {
					discoveries.splice(i, 1);
					$$invalidate(3, discoveries);
					return;
				}
			}
		};
	}

	let negStatus = "not started";

	function startNeg(wantPeerPub) {
		$$invalidate(6, negStatus = "Collecting local cands");

		localInfoGathered.then(() => {
			$$invalidate(1, peerPub = wantPeerPub);

			// Await connection updates
			neg.startNeg(peerPub, {
				factory: rtcFactory,
				localInfo,
				statusCb: status => {
					console.log("statusCb", status);
					$$invalidate(6, negStatus = status);
				},
				onTrack: onTrackHandler
			}).then(viewerSession => {
				viewerSession.connect();
			});
		});
	}

	function cookieAuth() {
		const authData = new URLSearchParams();
		authData.append("user", role);
		authData.append("pwd", "pass");

		fetch("/auth", { method: "POST", body: authData }).then(result => {
			if (Math.trunc(result.status / 100) == 2) {
				result.json().then(ret => {
					natsConnect(ret);
					console.log(ret);
				}).catch(errorHandler);
			} else {
				result.text().then(err => {
					errorHandler(err);
				});
			}
		}).catch(errorHandler);
	}

	cookieAuth();
	const click_handler = discovery => startNeg(discovery.pubKey);

	$$self.$set = $$props => {
		if ("role" in $$props) $$invalidate(0, role = $$props.role);
	};

	return [
		role,
		peerPub,
		natsState,
		discoveries,
		localInfo,
		audioStatus,
		negStatus,
		peer,
		startNeg,
		neg,
		errorHandler,
		localInfoGathered,
		rtcFactory,
		onTrackHandler,
		natsConnect,
		cookieAuth,
		click_handler
	];
}

class NatsRTCClient extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { role: 0 });
	}
}

export default NatsRTCClient;