
import { NatsConnection, NatsError, Msg, NatsSubscription } from "nats.ws";
import { NatsNegConnection } from './natsneg';
import { ConditionChecker, NegotiationOpts, NegState, NegStateOffer, parseTunnel, traverse, TunnelUpdateTopic } from './natsnegcommon';


export class NatsViewerSession {
    private opts: NegotiationOpts
    private neg: NatsNegConnection
    private myState: NegState = {offers: {}}
    private selectedHelper: string = ""
    private state: NegState
    private tunnelConn: NatsSubscription
    private checker: ConditionChecker
    private resolveStage1: (NatsViewerSession)=>void
    private role: string = "v"
    private peer: string
    private connectCalled: boolean = false

    private candsToJson(arr: RTCIceCandidate[]): Array<RTCIceCandidateInit> {
        let ret = Array<RTCIceCandidateInit>()
        arr.forEach(cand => {
            ret.push(cand.toJSON())
        });
        return ret
    }
    
    private collectOffer() {
        this.opts.statusCb("Creating offer", null)
        this.opts.rtcLc.makeOffer().then((offer) => {
                this.opts.localDescription = offer
                this.myState.offers![this.role].sdp = offer.sdp
                console.log("cands on creation", this.opts.rtcLc, this.opts.rtcLc.collectedCandidates)
                this.myState.offers![this.role].candidates = this.candsToJson(this.opts.rtcLc.collectedCandidates)
                let tinyTimer: any = null
                this.opts.rtcLc.onicecandidate = (ev) => {
                    if (!ev.candidate) {
                        return
                    }
                    console.log("cand", ev.candidate)
                    this.myState.offers![this.role].candidates = this.candsToJson(this.opts.rtcLc.collectedCandidates)
                    if (tinyTimer != null) {
                        clearTimeout(tinyTimer)
                    }
                    tinyTimer = setTimeout(() => {
                        this.pushUpdate()
                    }, 200)
                }
                console.log(this.myState)
                this.checker.update()
            })
    }

    public feedSid(sid: string, helper: string) {
        this.myState.sid = sid
        this.selectedHelper = helper
        this.checker.update()
    }

    public feedPeer(peer: string) {
        this.peer = peer
        this.checker.update()
    }

    private checkInitialOffer(resolve: ()=>void) {
        if (!this.selectedHelper) {
            return;
        }
        let offers = this.myState?.offers
        if (!offers) {
            return
        }
        let myOffer = offers[this.role]
        if (!myOffer || !myOffer.sdp) {
            return;
        }
        resolve()
        this.pushUpdate()
    }


    private checkHAccess(resolve: ()=>void) {
        let hPath = traverse(this.state, "offers.h")
        if (!hPath) {
            return
        }
        resolve() 
        {
            fetch(hPath).then((rsp) => {
                this.myState.offers![this.role].hlocal = (Math.trunc(rsp.status/100) == 2);
                this.checker.update()
            }).catch(() => {
                this.myState.offers![this.role].hlocal = false
                this.checker.update()
            })
        }
    }

    private checkReportHSdp(resolve: ()=>void) {
        let hlocal = traverse(this.myState, `offers.${this.role}.hlocal`)
        let sdp = traverse(this.myState, `offers.${this.role}.sdp`)
        if (hlocal == undefined || !sdp) {
            return
        }
        resolve()
        {
            this.pushUpdate()
        }
    }

    private checkDone(resolve: ()=>void) {
        let done = traverse(this.state, "offers.done")
        if (!done) {
            return
        }
        resolve()
        if (this.role == "v") {
            this.resolveStage1(this)
        } else {
            this.opts.onViewerReady!(this)
        }
    }

    public feedCandidate(cand: RTCIceCandidate, relatedHostCandidate: RTCIceCandidate | undefined) {
        this.myState.offers![this.role].lcand = relatedHostCandidate?.toJSON()
        this.myState.offers![this.role].rcand = cand.toJSON()
        this.checker.update()
    }

    public constructor(peer: string, opts: NegotiationOpts, neg: NatsNegConnection, role: string = "v") {
        this.role = role
        this.myState.offers![role] = {}
        this.opts = opts
        this.neg = neg
        this.peer = peer
        this.checker = new ConditionChecker({
            "push initial offer": this.checkInitialOffer.bind(this),
            "resolve offers.h": this.checkHAccess.bind(this),
            "push offers.h+sdp": this.checkReportHSdp.bind(this),
            "resolve when offers.done": this.checkDone.bind(this),
        })
        this.collectOffer()
    }

    public updateCands() {
        if (!this.connectCalled) {
            return;
        }
        let negOffer: NegStateOffer = this.state!.neg![this.role+"tou"]
        if (negOffer.candidates) {
            for (let i in negOffer.candidates) {
                this.opts.rtcLc.pc.addIceCandidate(negOffer.candidates[i])
            }
        }        
    }

    public connect() {
        this.opts.rtcLc.pc.setLocalDescription(this.opts.localDescription)
        this.connectCalled = true
        console.log("rtcLc", this.opts.rtcLc)
        //this.opts.rtcLc.pc.ontrack = this.opts.onTrack
        let negOffer: NegStateOffer = this.state!.neg![this.role+"tou"]
        this.opts.rtcLc.pc.setRemoteDescription({"type": "answer", "sdp": negOffer.sdp})
        this.updateCands()
        //this.opts.rtcLc.pc.addIceCandidate(this.state!.neg![this.role+"tou"].rcand!)
        //this.opts.rtcLc.pc.addIceCandidate(this.state!.neg![this.role+"tou"].lcand!)
    }



    public pushUpdate(): Promise<Msg> {
        if (this.myState.offers != undefined && 
            this.myState.offers[this.role] != undefined &&
            this.myState.offers[this.role].hlocal) 
        {
            this.myState.offers[this.role].ready = true
        }
        return this.neg.nats.request(this.neg.buildMySms(TunnelUpdateTopic, "h", this.selectedHelper), 1000, JSON.stringify(this.myState))
    }

    public doOfferNeg(): Promise<NatsViewerSession> {
        return new Promise<NatsViewerSession>((resolve, reject) => {
            this.resolveStage1 = resolve
            let topic = ""
            if (this.role == "v") {
                topic = this.neg.buildTunnel("*", this.peer, this.neg.pubKey)
            } else {
                topic = this.neg.buildTunnel("*", this.neg.pubKey, this.peer)
            }
            console.log("New tunnel", topic)
            
            this.neg.nats.subscribe(topic, (error: NatsError|null, msg: Msg) => {
                let hdrs = parseTunnel(msg.subject)
                if (hdrs.helper != this.selectedHelper) {
                    return
                }
                this.state = JSON.parse(msg.data)
                console.log("Tunnel state", this.state)
                this.checker.update()
                this.updateCands()
            }).then((tunnelConn) => {
                this.tunnelConn = tunnelConn
            })
        })
        
    }
}