import { IceAgent, RandomUfrag } from "./webrtc"

interface AddrInfo {
    addr: string
    randomPorts: boolean
    relatedHostCandidate?: RTCIceCandidate
    cand: RTCIceCandidate
}

export interface LocalInfo {
    ifs: Array<AddrInfo>
    cands: Array<RTCIceCandidate>
}

export function DiscoverLocalInfo(configuration: RTCConfiguration): Promise<LocalInfo> {
    return new Promise((resolve, reject) => {
        let localInfo: LocalInfo = {cands: [], ifs: []}
        let ipDiscoveryAgent = new IceAgent()
        ipDiscoveryAgent.dummyClient(RandomUfrag(), (ev: RTCPeerConnectionIceEvent) => {
            if (!ev || !ev.candidate) {
                return
            }
            console.log(ev.candidate)
            localInfo.cands = [...localInfo.cands, ev.candidate]
        }, configuration).then(() => {
            localInfo.ifs = []
            for (let i in localInfo.cands) {
                let c: RTCIceCandidate = localInfo.cands[i]
                if (!c.protocol || !c.protocol.startsWith("udp")) {
                    continue
                }
                if (c.type == "srflx") {
                    if (c.address == null) {
                        continue
                    }
                    let entry: AddrInfo = {addr: c.address, randomPorts: true, cand: c}
                    for (let j in localInfo.cands) {
                        let hc: RTCIceCandidate = localInfo.cands[j]
                        if (hc.type == "host" && hc.port == c.port) {
                            entry.randomPorts = false
                            entry.relatedHostCandidate = hc
                            break
                        }
                    }
                    localInfo.ifs = [...localInfo.ifs, entry]
                }
            }
            resolve(localInfo)
        })
    })
    
}