import * as jQuery from "jquery";
import { stringify } from 'querystring';
import {Updater} from "./common"

var dummyCert: RTCCertificate

function WhenHaveCert(fn) {
    if (!dummyCert) {
        RTCPeerConnection.generateCertificate({
            name: 'RSASSA-PKCS1-v1_5',
            hash: 'SHA-256',
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1])
        } as any).then((cert) => {
            dummyCert = cert;
            fn()
        })
    } else {
        fn()
    }
}

interface RTCIceCandidatePart {
    candidate: string;
    component?: RTCIceComponent;
    foundation?: string;
    port?: number;
    priority?: number;
    protocol?: RTCIceProtocol;
    relatedAddress?: string;
    relatedPort?: number;
    sdpMLineIndex?: number;
    sdpMid?: string | null;
    tcpType?: RTCIceTcpCandidateType;
    type?: RTCIceCandidateType;
    usernameFragment?: string;
}

export function GetSDPCreds(sdp: string): IceCred {
    let ufragM = (/\na=ice-ufrag:([^\s]+)/g).exec(sdp)
    let pwdM = (/\na=ice-pwd:([^\s]+)/g).exec(sdp)
    if (!ufragM || !ufragM[1] || !pwdM || !pwdM[1]) {
        throw "Can't parse SDP creds from '" + sdp + "'";
    }
    return {
        ufrag: ufragM[1],
        pwd: pwdM[1],
    }
}

export function ReplaceSDPCreds(sdp: string, ufrag: string, pwd: string): string {
    return sdp.replace(/\na=candidate:[^\n]*/g, "")
                .replace(/\na=ice-ufrag:[^\n]*/g, "\na=ice-ufrag:"+ufrag)
                .replace(/\na=ice-pwd:[^\n]*/g, "\na=ice-pwd:"+pwd)
}

export class IceAgent {
    peerConn: RTCPeerConnection
    iceServers: RTCIceServer[] = [{urls: "stun:stun.l.google.com:19302"}]

    public setIceServers(iceServers: RTCIceServer[]) {
        this.iceServers = iceServers
    }

    public createConnection() {
        this.peerConn = new RTCPeerConnection({certificates: [dummyCert], iceServers: this.iceServers})
        this.peerConn.createDataChannel("data")
    }

    public cleanupSdp(sdp: string, ufrag: string, pwd: string): string {
        return sdp.replace(/\na=candidate:[^\n]*/g, "")
                .replace(/\na=ice-ufrag:[^\n]*/g, "\na=ice-ufrag:"+ufrag)
                .replace(/\na=ice-pwd:[^\n]*/g, "\na=ice-pwd:"+pwd)
        
    }

    public close() {
        this.peerConn.close()
    }

    public dummyClient(ufrag: string, callback: ((this: RTCPeerConnection, ev: RTCPeerConnectionIceEvent) => any), configuration?: RTCConfiguration): Promise<RTCSessionDescriptionInit> {
        return new Promise((resolve, reject) => {
            WhenHaveCert(() => {
                this.createConnection();
                var rej = (reason) => { reject(reason) }
                this.peerConn.onicecandidate = function(ev) {
                    if (ev.candidate == null) {
                        resolve();
                    }
                    callback.bind(this)(ev)
                }
                this.peerConn.createOffer().then((offer) => {
                    this.peerConn.setLocalDescription(offer)
                    .then(() => {
                        if (!configuration) {
                            configuration = {iceServers: []}
                        }
                        var connHelper = new RTCPeerConnection(configuration)
                        connHelper.setRemoteDescription(offer)
                        connHelper.createAnswer()
                        .then((answer) => {
                            if (answer.sdp == undefined) {
                                throw "Unexpected empty SDP"
                            }
                            let sdp = this.cleanupSdp(answer.sdp, ufrag, "PortDependentForwarding0")
                            connHelper.close();
                            this.peerConn.setRemoteDescription({type: "answer", sdp: sdp})
                            .catch(rej)
                        }).catch(rej)
                    }).catch(rej)
                }).catch(rej);
            })
        })
        
    }
}


export enum ConnectivityType {
    None = 'no_connectivity',
    NotFound = 'not_found',
    Mapped = 'mapped',
    NotMapped = 'not_mapped',
    NoCandidates = 'no_candidates',
}

export interface ConnectivityResult {
    type: ConnectivityType
    randomPorts?: boolean
    endpoint: string | null
    ufrag: string
}


export function CandidateType(cand: RTCIceCandidate): EndpointStyle {
    if (!cand) {
        return EndpointStyle.Unknown
    }
    let line = cand.candidate
    // candidate: "candidate:3717574005 1 udp 1686052607 10.22.0.2 43542 typ srflx raddr 172.18.0.1 rport 43542 generation 0 ufrag cnRm network-id 1 network-cost 50"

    let portIdx = line.indexOf(" rport ")
    if (portIdx == -1) {
        return EndpointStyle.Unknown
    }
    portIdx += 7
    let localPort = line.substr(portIdx).split(' ')[0]
    let udpIdx = line.indexOf(" udp ")
    if (udpIdx == -1) {
        return EndpointStyle.Unknown
    }
    udpIdx += 5
    let remotePort = line.substr(udpIdx).split(' ')[2]
    if (!localPort || !remotePort) {
        return EndpointStyle.Unknown
    }
    if (localPort == remotePort) {
        return EndpointStyle.Strong
    }
    return EndpointStyle.Weak
}

export enum EndpointStyle {
    Strong = "strong",
    Weak = "weak",
    Strange = "strange",
    Unknown = "unk"
}

export function ConnectivityToEndpoint(connInfo: ConnectivityResult): EndpointStyle {
    if (!connInfo) {
        return EndpointStyle.Unknown
    }
    if (!connInfo.randomPorts) {
        return EndpointStyle.Strong
    }
    if (connInfo.type == ConnectivityType.Mapped) {
        return EndpointStyle.Strange
    }
    return EndpointStyle.Weak
}



export interface IceCred {
    ufrag: string
    pwd: string
}

export function SdpToIceCreds(sdp: string): IceCred[] {
    let ufs: string[] = []
    let pwds: string[] = []
    let ufragre = /\na=ice-ufrag:([^\r\n]*)/g
    let pwdre = /\na=ice-pwd:([^\r\n]*)/g
    while (true) {
        let m = ufragre.exec(sdp);
        if (m) { ufs.push(m[1]) } else {  break  }
    }
    while (true) {
        let m = pwdre.exec(sdp);
        if (m) { pwds.push(m[1]) } else {  break  }
    }
    if (ufs.length != pwds.length) {
        return []
    }
    let ret: IceCred[] = []
    for (let i = 0; i<ufs.length; ++i) {
        ret.push({ufrag: ufs[i], pwd: pwds[i]})
    }
    return ret
}


export enum CheckerState {
    Started,
    GatheringCandidates,
    ReceivingOffer,
    PollingForResult,
    Complete
}

export class ConnectivityChecker {
    private base_url: string

    public max_retries: number = 3
    public onStateChange: (this: ConnectivityChecker, ev: CheckerState) => any
    public random_ports: boolean = true;
    public polling_period: number = 2000
    public not_mapped_retry_period: number = 200

    

    private _onStateChange(ev: CheckerState) {
        if (this.onStateChange) {
            this.onStateChange(ev)
        }
    }

    constructor(base_url: string) {
        this.base_url = base_url
    }

    public check() : Promise<ConnectivityResult> {
        return new Promise((resolve, reject) => {
            let rej = (reason: any) => { reject(reason) }
            this._onStateChange(CheckerState.Started)
            
            let agent = new IceAgent()
            let collectedCands: Array<RTCIceCandidatePart> = [];
            let ufrag = RandomUfrag()
            let base_url = this.base_url;
            this._onStateChange(CheckerState.GatheringCandidates)
            agent.dummyClient(ufrag, (ev) => {
                console.log(ev.candidate)
                let c = ev.candidate
                if (c != null) {
                    collectedCands.push({candidate: c.candidate, sdpMid: c.sdpMid});
                } else {
                    this._onStateChange(CheckerState.ReceivingOffer)
                    // No more candidates
                    jQuery.ajax({
                        type: "POST",
                        url: base_url + '/trickle',
                        data: JSON.stringify({"ufrag": ufrag, "cands": collectedCands}),
                    })
                    .done((retJson) => {
                        let ufrag = ""
                        for (let i in retJson) {
                            agent.peerConn.addIceCandidate(retJson[i])
                            ufrag = retJson[i].ufrag
                            this.random_ports = retJson[i].rnd
                        }
                        if (!ufrag) {
                            resolve({
                                type: ConnectivityType.NoCandidates, 
                                endpoint: null, 
                                randomPorts: undefined,
                                ufrag: '',
                            })
                            return;
                        }
                        let upd = new Updater(this.polling_period);
                        let retries = 0
                        let retriedNotMapped = false
                        let cachedResult = ConnectivityType.NotFound
                        this._onStateChange(CheckerState.PollingForResult)
                        upd.start(() => {
                            jQuery.ajax({
                                type: "POST",
                                url: base_url + "/result",
                                data: JSON.stringify({"ufrag": ufrag})
                            }).done((resultJson) => {
                                let result = resultJson['result']
                                if (result && result != ConnectivityType.NotFound) {
                                    cachedResult = result;
                                }
                                if (retriedNotMapped && result != ConnectivityType.Mapped) {
                                    result = ConnectivityType.NotMapped
                                }
                                if (retries < this.max_retries) {
                                    if (result == ConnectivityType.NotFound || result == ConnectivityType.None) {
                                        retries += 1
                                        upd.scheduleAgain()
                                        return
                                    }
                                }
                                if (result == ConnectivityType.NotMapped) {
                                    if (!retriedNotMapped) {
                                        retriedNotMapped = true;
                                        upd.scheduleAgain(this.not_mapped_retry_period)
                                        return;
                                    }
                                }
                                agent.close();
                                resolve({type: cachedResult, randomPorts: this.random_ports, endpoint: resultJson.ep, ufrag: ufrag})
                                this._onStateChange(CheckerState.Complete)
                            }).fail(rej)
                        })
                    }).fail(rej)
                    return;
                }
            })
        })
    }
}


export class RTCLifeCycle {
    public pc: RTCPeerConnection
    public data: RTCDataChannel
    onicecandidate: ((ev: RTCPeerConnectionIceEvent) => any)
    public collectedCandidates: RTCIceCandidate[] = []
    public tracks: RTCTrackEvent[] = []

    constructor(pc: RTCPeerConnection) {
        this.pc = pc
        pc.onicecandidate = (ev: RTCPeerConnectionIceEvent) => {
            if (ev.candidate != null) {
                this.collectedCandidates.push(ev.candidate)
            }
            if (this.onicecandidate) {
                this.onicecandidate(ev)
            }
        }
        pc.onicecandidateerror = (ev: RTCPeerConnectionIceErrorEvent) => {
            console.log(ev.errorText + " " + ev.errorCode)
        }
        pc.ontrack = (ev: RTCTrackEvent) => {
            if (ev.track) {
                this.tracks.push(ev)
            }
        }
    }

    public processTracks(handler: ((this: RTCPeerConnection, ev: RTCTrackEvent) => any)) {
        this.tracks.forEach(el => {
            handler.bind(this.pc)(el)
        })
        this.pc.ontrack = handler
    }

    public makeOffer(): Promise<RTCSessionDescriptionInit> {
        return this.pc.createOffer()
    }

    public makeAnswer(offer: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
        return new Promise((resolve, reject) => {
            this.pc.setRemoteDescription(offer)
                .then(() => {
                    this.pc.createAnswer()
                        .then((desc: RTCSessionDescriptionInit) => {
                            resolve(desc)
                        })
                        .catch(reject)
                })
                .catch(reject)
            
        })
        
    }
}


const randomCset = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ"


export function RandomUfrag(): string {
    let ufrag = ""
    for (let i = 0; i<8; ++i) {
        ufrag += randomCset[Math.trunc(Math.random() * randomCset.length)]
    }
    return ufrag
}

export function RandomPwd(): string {
    let pwd = ""
    for (let i = 0; i<22; ++i) {
        pwd += randomCset[Math.trunc(Math.random() * randomCset.length)]
    }
    return pwd
}

interface tcProvider {
    tranceiver: RTCRtpTransceiverInit
    track: string
}


export class RTCFactory {
    hasData: boolean
    tracks: MediaStreamTrack[] = []
    tranceivers: tcProvider[] = []
    config?: RTCConfiguration;
    natatat: boolean;
    public provideDataStream() {
        this.hasData = true
    }

    public provideTranceiver(track: string, tc: RTCRtpTransceiverInit) {
        this.tranceivers.push({track: track, tranceiver: tc})
    }

    public provideTrack(track: MediaStreamTrack) {
        this.tracks.push(track)
    }

    public provideConfiguration(config: RTCConfiguration) {
        this.config = config
    }

    public provideNatatat(natatat: boolean) {
        this.natatat = natatat
    }

    public createLifecycle(init?: any): Promise<RTCLifeCycle> {
        return new Promise((resolve, reject) => {
            WhenHaveCert(() => {
                if (!this.config) {
                    this.config = {}
                }
                this.config.certificates = [dummyCert]
                if (this.natatat) {
                    this.config.iceServers = []
                    this.config.bundlePolicy = "max-bundle"
                    this.config.rtcpMuxPolicy = "require"
                }
                let pc = new RTCPeerConnection(this.config)
                let lc = new RTCLifeCycle(pc)
                if (this.hasData) {
                    lc.data = pc.createDataChannel("data")
                }
                for (let i = 0; i<this.tracks.length; ++i) {
                    pc.addTrack(this.tracks[i])
                }
                for (let i = 0; i<this.tranceivers.length; ++i) {
                    let tc = this.tranceivers[i]
                    pc.addTransceiver(tc.track, tc.tranceiver)
                }
                if (init) {
                    init(lc)
                }
                resolve(lc)
            })
        })
    }

}

