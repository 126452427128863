
export class Updater {

    func: any
    timeout: number
    pending: number

    constructor(timeout: number) {
        this.timeout = timeout
    }

    public cancel() {
        clearTimeout(this.pending)
    }

    public scheduleAgain(oneShotTimeout?: number) {
        if (!oneShotTimeout) {
            oneShotTimeout = this.timeout
        }
        setTimeout(this.func, oneShotTimeout)
    }

    public start(func) {
        this.func = func
        setTimeout(func, this.timeout)
    }
}


export function onReady(fn) {
    if (document['attachEvent'] ? document.readyState === "complete" : document.readyState !== "loading"){
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}