
import { NatsConnection, NatsError, Msg, NatsSubscription } from "nats.ws";
import { clearInterval } from 'timers';
import { ConnectionOptions } from 'tls';
import { LocalInfo } from './nttrtc/iface_discovery'
import { NegAnnouncer, PeerHolders, NatsNegOptions, parseAnnounce, parseSms, SelectArbiterTopic, MsgSelectArbiterReq, MsgSelectArbiterRes, MsgMksidReq, MsgMksidRsp, NegotiationOpts } from './natsnegcommon'
//import { NatsStreamerSession } from './natsstreamersession'
import { NatsViewerSession } from './natsviewersession';

export enum NatsState {
    NotConnected,
    Connecting,
    Connected,
}


export class NatsNegConnection {
    nats: NatsConnection
    jwt: any
    pubKey: string
    role: string
    announcer: NegAnnouncer | null
    state: NatsState
    peers: PeerHolders = {}
    timeoutTimer: NodeJS.Timeout

    onConnectionStateChanged: (ns: NatsState, reason: string) => void = () => {}
    onDiscoveredPeer: (msg: Msg) => void = () => {}
    onDisconnectedPeer: (msg: Msg) => void = () => {}

    private emitOnDiscoveredPeer(msg: Msg) {
        this.onDiscoveredPeer(msg)
    }

    private processAnnounce(msg: Msg, data: any) {
        if (data.gone) {
            delete this.peers[msg.subject]
            this.emitOnDisconnectedPeer(msg)
            return
        }
        let updated = !!this.peers[msg.subject]
        this.peers[msg.subject] = {a: data, lastSeen: new Date().getTime()}
        if (!updated) {
            this.emitOnDiscoveredPeer(msg)
        }
    }

    private emitOnDisconnectedPeer(msg: Msg) {
        this.onDisconnectedPeer(msg)
    }

    private emitOnConnectionStateChanged(ns: NatsState, reason: string) {
        if (ns == NatsState.Connected) {
            this.announcer = new NegAnnouncer(this.nats, this.role, this.pubKey)
            this.timeoutTimer = setInterval(() => {
                let now = new Date().getTime()
                for (let k in this.peers) {
                    let val = this.peers[k]
                    if ((now - val.lastSeen) > 25000) {
                        delete this.peers[k]
                        this.emitOnDisconnectedPeer({subject: k, timeout: true})
                        break
                    }
                }
            }, 5000)
        } else {
            if (this.announcer) {
                this.announcer.stop()
            }
            if (this.timeoutTimer) {
                clearInterval(this.timeoutTimer)
            }
            this.announcer = null
        }
        this.onConnectionStateChanged(ns, reason)
    }

    constructor(opts: ConnectionOptions, negOpts: NatsNegOptions) {
        this.jwt = negOpts.unsignedJwt
        let allSubj: Array<string> = this.jwt.nats.pub.allow
        for (let i = 0; i<allSubj.length; ++i) {
            if (allSubj[i].startsWith("neg.announce.")) {
                let a = parseAnnounce(allSubj[i])
                this.role = a.role
                this.pubKey = a.pubKey
                break
            }
        }
        new Promise(() => {
            this.emitOnConnectionStateChanged(NatsState.Connecting, "")
            NatsConnection.connect(opts).then((nc) => {
                this.nats = nc
                this.nats.subscribe("neg.announce.>", (error: NatsError|null, msg: Msg) => {
                    let msgData = JSON.parse(msg.data)
                    if (!msgData) {
                        return
                    }
                    if (msgData["new"]) {
                        if (this.announcer) {
                            this.announcer.send({})
                        }
                    }
                    this.processAnnounce(msg, msgData)
                })
                this.emitOnConnectionStateChanged(NatsState.Connected, "")
            }).catch((err) => {
                this.emitOnConnectionStateChanged(NatsState.NotConnected, err)
            })
        })        
    }

    public buildMySms(topic: string, tgtRole: string, tgtPub?: string) {
        if (tgtPub) {
            tgtRole = tgtRole + "." + tgtPub
        }
        return `neg.${topic}.sms.${this.role}.${this.pubKey}.to.${tgtRole}`
    }

    public buildTunnel(helper: string, streamer: string, viewer: string) {
        return `neg.tunnel.${helper}.${streamer}.${viewer}`
    }

    public listenForViewers(opts: NegotiationOpts) {
        this.nats.subscribe(buildSms(SelectArbiterTopic, "v", "*", "s", this.pubKey), (error: NatsError|null, msg: Msg) => {
            let areq: MsgSelectArbiterReq = JSON.parse(msg.data)
            console.log("Selarb", areq)
            let ares: MsgSelectArbiterRes = {helper: areq.defHelper, sid: areq.defSid}
            if (ares.helper && ares.sid) {
                opts.statusCb("Creating LC", null)
                opts.factory.createLifecycle().then((lc) => {
                    opts.rtcLc = lc
                    lc.pc.ontrack = opts.onTrack
                    opts.statusCb("Created LC", null)
                    let smsFields = parseSms(msg.subject)
                    let sess = new NatsViewerSession(smsFields.sender, opts, this, "s")
                    sess.feedSid(ares.sid, ares.helper)

                    {
                        let found = false
                        let prio = 0;
                        for (let k in this.peers) {
                            let val = this.peers[k]
                            let a = parseAnnounce(k)
                            if (a.role != 'h') {
                                continue
                            }
                            if (k != ares.helper) {
                                continue
                            }
                            if (val.a.ext_ip) {
                                for (let i in opts.localInfo.ifs) {
                                    let iface = opts.localInfo.ifs[i]
                                    if (iface.addr == val.a.ext_ip) {
                                        sess.feedCandidate(iface.cand, iface.relatedHostCandidate)
                                        break
                                    }
                                }
                            }
                        }
                        if (!found) {
                            // If not found, fallback to the first proxy
                            console.log("Finding helper...", this)
                            for (let k in this.peers) {
                                let val = this.peers[k]
                                let a = parseAnnounce(k)
                                if (a.role != 'h' || !val.a.flags || val.a.flags.indexOf('p') == -1) {
                                    continue
                                }
                                // TODO: find a proper cand
                                let iface = opts.localInfo.ifs[0]
                                sess.feedCandidate(iface.cand, iface.relatedHostCandidate)
                                break
                            }
                        }
                        
                    }

                    
                    console.log("selarbres", ares)
                    msg.respond(JSON.stringify(ares))
                    sess.doOfferNeg()

                    sess.pushUpdate()
                })
            }
        })
    }


    // Returns when Stage 1 is complete
    public startNeg(peer: string, opts: NegotiationOpts): Promise<NatsViewerSession> {
        return new Promise(async (resolve, reject) => {
            // Maybe suggest a helper based on LocalInfo

            opts.statusCb("Creating LC", null)
            let lc = await opts.factory.createLifecycle()
            opts.rtcLc = lc
            lc.pc.ontrack = opts.onTrack
            opts.statusCb("Created LC", null)
            
            let vc = new NatsViewerSession(peer, opts, this)
            vc.doOfferNeg().then(resolve, reject)

            console.log(`Considering peers`)
            let defHelper: string = ""
            let defSid: string = ""
            let profile = "default"
            {
                let prio = 0;
                for (let k in this.peers) {
                    let val = this.peers[k]
                    let a = parseAnnounce(k)
                    if (a.role != 'h') {
                        continue
                    }
                    if (val.a.ext_ip) {
                        for (let i in opts.localInfo.ifs) {
                            let iface = opts.localInfo.ifs[i]
                            if (iface.addr == val.a.ext_ip) {
                                let sidReq: MsgMksidReq = {priority: prio++, streamer: peer, profile: profile}
                                console.log("mksid", sidReq)
                                let rsp: MsgMksidRsp
                                try {
                                    let natsRsp: Msg = await this.nats.request(this.buildMySms("mksid", a.role, a.pubKey), 1000, JSON.stringify(sidReq))
                                    console.log(natsRsp)
                                    natsRsp.data = JSON.parse(natsRsp.data)
                                    rsp = natsRsp.data
                                    if (!rsp.sid) {
                                        continue
                                    }
                                } catch (err) {
                                    // ignore
                                    console.log(err)
                                    continue
                                }
                                defSid = rsp.sid
                                defHelper = a.pubKey

                                vc.feedCandidate(iface.cand, iface.relatedHostCandidate)
                                break
                            }
                        }
                    }
                }
                // If not found, fallback to the first proxy
                console.log("Finding helper...", this)
                if (!defHelper || !defSid) {
                    for (let k in this.peers) {
                        let val = this.peers[k]
                        let a = parseAnnounce(k)
                        if (a.role != 'h' || !val.a.flags || val.a.flags.indexOf('p') == -1) {
                            continue
                        }
                        let sidReq: MsgMksidReq = {priority: prio++, streamer: peer, profile: profile}
                        let rsp: MsgMksidRsp
                        try {
                            console.log("mksid", sidReq)
                            let natsRsp: Msg = await this.nats.request(this.buildMySms("mksid", a.role, a.pubKey), 1000, JSON.stringify(sidReq))
                            console.log(natsRsp)
                            natsRsp.data = JSON.parse(natsRsp.data)
                            rsp = natsRsp.data
                            if (!rsp.sid) {
                                continue
                            }
                        } catch (err) {
                            // ignore
                            console.log(err)
                            continue
                        }
                        defSid = rsp.sid
                        defHelper = a.pubKey

                        // TODO: find a proper cand
                        let iface = opts.localInfo.ifs[0]
                        vc.feedCandidate(iface.cand, iface.relatedHostCandidate)
                        break
                    }
                }
            }
            if (!defHelper || !defSid) {
                opts.statusCb("Cannot find a suitable helper", null)
                reject("Cannot find a suitable helper")
                return
            }
            opts.statusCb("Settling sids", null)
            
            let selArb: MsgSelectArbiterReq = {defHelper: defHelper, defSid: defSid}
            let nr: Msg = await this.nats.request(this.buildMySms(SelectArbiterTopic, "s", peer), 2000, JSON.stringify(selArb))
            nr.data = JSON.parse(nr.data)
            let selArbRsp: MsgSelectArbiterRes = nr.data

            console.log("Selected helper", selArbRsp.helper)

            opts.statusCb("Stage 1 confirmation", null)
            vc.feedSid(selArbRsp.sid, selArbRsp.helper)
        })
    }
    
}


export function pollFunction<T>(pollFunc: (() => Promise<T>), isAgain: ((result: T | null, error: any, attempt: number) => boolean), interval: number = 500): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        let attempt = 0
        var tryAgain: (()=>any);
        tryAgain = () => {
            attempt += 1
            pollFunc()
                .then((res) => {
                    if (isAgain(res, null, attempt)) {
                        setTimeout(() => {
                            tryAgain()
                        }, interval)
                    } else {
                        resolve(res)
                    }
                })
                .catch((err) => {
                    if (isAgain(null, err, attempt)) {
                        setTimeout(() => {
                            tryAgain()
                        }, interval)
                    } else {
                        reject(err)
                    }
                })
        }
        tryAgain()        
    })
    
}

export function buildSms(topic: string, fromRole: string, fromPub: string, tgtRole: string, tgtPub?: string) {
    if (tgtPub) {
        tgtRole = tgtRole + "." + tgtPub
    }
    return `neg.${topic}.sms.${fromRole}.${fromPub}.to.${tgtRole}`
}

