
import { RTCFactory, RTCLifeCycle } from './nttrtc/webrtc';
import { NatsConnection, NatsError, Msg, NatsSubscription } from "nats.ws";
import { LocalInfo } from './nttrtc/iface_discovery';
import { NatsStreamerSession } from './natsstreamersession';
import { NatsViewerSession } from './natsviewersession';

export class Verdict {
    public accept() {
        // SMS peer, then report success to helper
    }
}

export class NegAnnouncer {
    conn: NatsConnection
    announceSubj: string
    ticker: NodeJS.Timeout

    public send(data: any) {
        this.conn.publish(this.announceSubj, JSON.stringify(data))
    }

    constructor(conn: NatsConnection, role: string, pub: string) {
        this.conn = conn
        this.announceSubj = "neg.announce." + role + "." + pub
        if (this.announceSubj != undefined) {
            this.send({"new": true})
            this.ticker = setInterval(() => {
                this.send({})
            }, 15000)
        }
    }

    public stop() {
        clearInterval(this.ticker)
    }
}

export interface NatsNegOptions {
    unsignedJwt: any
}

interface PeerHolder {
    a: any
    lastSeen: number
}

// Sent V2H, topic "mksid"
export interface MsgMksidReq {
    priority: number
    streamer: string
    profile: string
}

// Sent H2V, topic "mksid"
export interface MsgMksidRsp {
    sid: string
}

export const SelectArbiterTopic = "selarb"

// Sent V2S, topic "selarb"
export interface MsgSelectArbiterReq {
    defHelper: string
    defSid: string
}

// Responded S2V, topic "selarb"
export interface MsgSelectArbiterRes {
    helper: string
    sid: string
}

export interface NegStateOffer {
    sdp?: string
    hlocal?: boolean
    candidates?: Array<RTCIceCandidate>
    ready?: boolean
    lcand?: RTCIceCandidateInit
    rcand?: RTCIceCandidateInit
    closed?: boolean
}

interface NegStateStage1 {
    h?: string
    v?: NegStateOffer
    s?: NegStateOffer
    done?: boolean
}

interface NegStateStage2 {
    vtou: NegStateOffer
    stou: NegStateOffer
    rule: string
    // proxy: NegStateOffer
    // vready: boolean
    // sready: boolean
    // srcand: RTCIceCandidateInit
    // slcand: RTCIceCandidateInit
    // vrcand: RTCIceCandidateInit
    // vlcand: RTCIceCandidateInit
    // ssdp: RTCSessionDescriptionInit
    done: boolean
}

export const TunnelUpdateTopic = "upd"

// Exchanged V2H, S2H, H2S, H2V
export interface NegState { 
    sid?: string
    offers?: NegStateStage1
    neg?: NegStateStage2
}

interface AnnounceSubj {
    role: string
    pubKey: string
}

export function parseAnnounce(announce: string): AnnounceSubj {
    let parts = announce.split('.')
    return {pubKey: parts[3], role: parts[2]}
}

interface SmsSubj {
    topic: string
    senderRole: string
    sender: string
}

export function parseSms(subj: string): SmsSubj {
    //"neg.topic.sms.senderRole.sender"
    let parts = subj.split('.')
    return {topic: parts[1], senderRole: parts[3], sender: parts[4]}
}


interface TunnelSubj {
    streamer: string
    viewer: string
    helper: string
}

export function parseTunnel(subj: string): TunnelSubj {
    //"neg.tunnel.helper.streamer.viewer"
    let parts = subj.split('.')
    return {helper: parts[2], streamer: parts[3], viewer: parts[4]}
}

export interface PeerHolders {
    [x: string]: PeerHolder;
}

interface WaitingActions {
    [idx: string]: (resolve: () => void) => void
}

export class ConditionChecker {
    remainingChecks: WaitingActions
    checkInProgress: boolean
    needMoreChecks: boolean

    constructor(remainingChecks: WaitingActions) {
        this.remainingChecks = remainingChecks
    }


    public update() {
        if (this.checkInProgress) {
            this.needMoreChecks = true
            return
        }
        this.needMoreChecks = false
        this.checkInProgress = true
        let resolvedSomething = false
        for (let i in this.remainingChecks) {
            this.remainingChecks[i](() => {
                console.log("Resolved ", i)
                resolvedSomething = true
                delete this.remainingChecks[i]
            })
        }
        this.checkInProgress = false
        if (this.needMoreChecks && resolvedSomething) {
            this.update()
        }
    }
}

export interface NegotiationOpts {
    localDescription: RTCSessionDescriptionInit;
    rtcLc: RTCLifeCycle;
    //peer: string
    factory: RTCFactory
    localInfo: LocalInfo
    statusCb: ((status: string, details: any) => any)
    //selectedHelper?: string
    //sid?: string
    //myState?: NegState
    state?: NegState
    onViewerReady?: ((opts: NatsViewerSession) => any)
    onTrack: ((ev: Event) => any)
}

export function traverse(obj: any, path: string): any {
    let parts = path.split(".")
    for (let i = 0; i<parts.length; ++i) {
        if (obj == undefined) {
            return obj
        }
        obj = obj[parts[i]]
    }
    return obj
}


